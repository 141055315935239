// ErrorBoundary.jsx
import React from "react";
import styles from "./errorBoundary.module.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  handleRefresh = () => {
    window.location.reload();
  };

  handleHomeClick = () => {
    window.location.href = '/';
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.content}>
              <div className={styles.header}>
                <h2 className={styles.title}>😢 An error has occurred</h2>
              </div>

              <div className={styles.message}>
                <p>Unfortunately, the application encountered an unexpected error.</p>
                <p>We are already working to eliminate it. Please try reloading the page or return to the main page.</p>
              </div>

              <div className={styles.actions}>
                <button
                  onClick={this.handleHomeClick}
                  className={`${styles.button} ${styles.buttonSecondary}`}
                >
                  Go to main page
                </button>
                <button
                  onClick={this.handleRefresh}
                  className={`${styles.button} ${styles.buttonPrimary}`}
                >
                  Reload page
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;