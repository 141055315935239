// log the pageview with their URL
export const pageview = (url, userId = null) => {
	console.log(window?.gtag)

	if (process.env.NEXT_PUBLIC_IS_GLOBAL_VERSION === "true" && window?.gtag !== undefined) {
		window?.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
			page_path: url,
			user_id: userId,
		});
	} else {
		return false;
	}
};

// log specific events happening.
export const event = ({ action, params }) => {
	if (process.env.NEXT_PUBLIC_IS_GLOBAL_VERSION === "true" && window?.gtag !== undefined) {
		window?.gtag("event", action, params);
	} else {
		return false;
	}
};

// log user plan
export const userPlan = (userPlan) => {
	if (process.env.NEXT_PUBLIC_IS_GLOBAL_VERSION === "true" && window?.gtag !== undefined) {
		window?.gtag("set", "user_properties", {
			plan_type: userPlan,
		});
	} else {
		return false;
	}
};
