import { createContext, useReducer, useContext } from "react";

const notAuthRestrictions = [
	{ status: "start", active: true, size: 60 * 1024 * 1024 },
	{ status: "creator", active: false, size: 120 * 1024 * 1024 },
	{ status: "pro", active: false, size: "Infinity" },
];

const INITIAL_STATE = {
	load: false,
	isAuth: false,
	userInfo: null,
	accountData: null,
	video: null,
	job: null,
	backgrounds: [],
	task: null,
	previewImg: null,
	pricing: null,
	resultURL: null,
	downloadURL: null,
	restrictions: JSON.parse(JSON.stringify(notAuthRestrictions)),
	error_msg: null,
	error_code: null,
	error_limit_msg: null,
	error_restriction_size: null,
	userVideos: null,
	checkout: null,
	grecaptchaToken: null,
	option: null,
	editorData: null,
	isDownload: false,
	editorControls: null,
	textToImgTask: null,
	textToImgResults: [],
	styleTransTask: null,
	styleTransImg: null,
	styleTransResults: [],
	interiorRedesignTask: null,
	interiorRedesignImg: null,
	interiorRedesignResults: [],
	eraseAndReplaceImg: [],
	eraseAndReplaceTasksId: [],
	eraseAndReplaceTask: null,
	extendImg: [],
	extendTask: null,
	extendResults: [],
	editorImage: null,
	editorResult: null,
	illustrationTask: null,
	devMode: false,
	promotion: false,
	temporaryFileStorage: null
};

function reducer(state, action) {
	(state.devMode) && console.log(action.type);
	switch (action.type) {
		case "setTemporaryFile":
			return {
				...state,
				temporaryFileStorage: action.file,
			};
		case "setPromotion":
			return {
				...state,
				promotion: action.promotion,
			};
		case "setAuth":
			const { userInfo } = action;
			window?.gr("track", "conversion", { email: userInfo.userData.email.toLowerCase() });
			return {
				...state,
				load: true,
				isAuth: true,
				userInfo: userInfo,
				backgrounds: [],
				video: null,
				task: null,
				resultURL: null,
				downloadURL: null,
				checkout: null,
				option: null,
				editorData: null,
			};
		case "setLogOut":

			localStorage.removeItem("activeGroup");
			localStorage.removeItem("countdownChristmasTime");
			localStorage.removeItem("countdownChristmasTimestamp");
			localStorage.removeItem("timerChristmasExpired");
			localStorage.removeItem("isLogged");
			sessionStorage.removeItem("searchParams");

			return {
				...state,
				load: true,
				isAuth: false,
				userInfo: null,
				accountData: null,
				restrictions: JSON.parse(JSON.stringify(notAuthRestrictions)),
				video: null,
				job: null,
				previewImg: null,
				task: null,
				resultURL: null,
				downloadURL: null,
				checkout: null,
				option: null,
				editorData: null,
				textToImgTask: null,
				textToImgResults: [],
				styleTransTask: null,
				styleTransImg: null,
				styleTransResults: [],
				interiorRedesignTask: null,
				interiorRedesignImg: null,
				interiorRedesignResults: [],
				eraseAndReplaceImg: [],
				eraseAndReplaceTasksId: [],
				eraseAndReplaceTask: null,
				extendImg: [],
				extendTask: null,
				extendResults: [],
				editorImage: null,
				editorResult: null,
			};
		case "setAccountData":
			return {
				...state,
				load: true,
				accountData: action.accountData,
				restrictions: action.restrictions,
			};
		case "setVideo":
			return {
				...state,
				video: action.video,
				grecaptchaToken: action.grecaptchaToken,
				job: null,
				previewImg: null,
				task: null,
				resultURL: null,
				downloadURL: null,
				checkout: null,
				option: null,
				editorData: null,
			};
		case "setVideoDel":
			return {
				...state,
				video: null,
				job: null,
				previewImg: null,
				task: null,
				resultURL: null,
				downloadURL: null,
				checkout: null,
				grecaptchaToken: null,
				option: null,
				editorData: null,
				isDownload: action.isDownload,
				textToImgStatus: null,
				queuePlace: null,
				timeLeft: null,
				searchResults: null,
				searchParams: null,
			};
		case "setJob":
			return {
				...state,
				job: action.job,
				previewImg: null,
				task: null,
				resultURL: null,
				downloadURL: null,
				checkout: null,
				grecaptchaToken: null,
				option: null,
				editorData: null,
			};
		case "setBackgrounds":
			return {
				...state,
				backgrounds: action.backgrounds,
			};
		case "setSearchResults":
			return {
				...state,
				searchResults: action.data,
			};
		case "setTask":
			if (action.action === "init") {
				return {
					...state,
					task: action.task,
				};
			}
			if (action.action === "update") {
				return {
					...state,
					task: state.task !== null ? action.task : null,
				};
			}
		case "setPreviewImg":
			return {
				...state,
				previewImg: action.previewImg,
				video: null,
				task: null,
			};
		case "setPreviewWithMask":
			return {
				...state,
				previewImg: action.previewWithMask,
			};
		case "setTrimmedFileInfo":
			return {
				...state,
				previewImg: action.previewImg,
				job: action.job,
				task: null,
				video: null,
			};
		case "setPricing":
			return {
				...state,
				pricing: action.pricing,
			};
		case "setResultURL":
			return {
				...state,
				resultURL: action.resultURL,
				downloadURL: action.downloadURL,
				task: null,
			};
		case "theEnd":
			return {
				...state,
				previewImg: null,
				resultURL: null,
				downloadURL: null,
				job: null,
				video: null,
				checkout: null,
				option: null,
				editorData: null,
			};
		case "setVideoError":
			return {
				...state,
				error_msg: action.error_msg,
				error_code: action.error_code,
				error_restriction_size: action.error_restriction_size ? action.error_restriction_size : null,
				previewImg: null,
				resultURL: null,
				downloadURL: null,
				job: null,
				video: null,
				checkout: null,
				error_limit_msg: null,
				option: null,
				editorData: null,
			};
		case "setLimitError":
			return {
				...state,
				error_limit_msg: action.error_msg ? action.error_msg : null,
				error_msg: null,
				error_code: action.error_code ? action.error_code : null,
			};
		case "setLoadingStatus":
			return {
				...state,
				load: true,
			};
		case "setRecoveryJob":
			return {
				...state,
				job: action.job,
				task: action.task,
				option: action.option,
				previewImg: action.previewImg,
				editorData: action.editorData,
			};
		case "setContinueEdit":
			return {
				...state,
				job: action.job,
				task: action.task,
				option: action.option,
				previewImg: action.previewImg,
				editorData: action.editorData,
				downloadURL: action.downloadURL,
				resultURL: action.resultURL,
			};
		case "setUserVideos":
			return {
				...state,
				userVideos: action.userVideos,
			};
		case "setCheckout":
			return {
				...state,
				checkout: action.checkout,
			};
		case "setRecoveryCheckout":
			return {
				...state,
				job: action.job,
				checkout: action.checkout,
				option: action.option,
				previewImg: action.previewImg,
				editorData: action.editorData,
			};
		case "setOption":
			return {
				...state,
				option: { ...state.option, ...action.option },
			};
		case "setDocsPhotoParams":
			return {
				...state,
				docsPhotoBackground: action.background,
				docsPhotoAspectRation: action.aspectRation,
			};
		case "setTextToImgTask":
			return {
				...state,
				textToImgTask: action.task,
			};
		case "setTextToImgResults":
			return {
				...state,
				textToImgResults: [...action.results],
			};
		case "setStyleTransTask":
			return {
				...state,
				styleTransTask: action.task,
			};
		case "setStyleTransImg":
			return {
				...state,
				styleTransImg: action.image,
			};
		case "setStyleTransResults":
			return {
				...state,
				styleTransResults: [...action.results],
			};
		case "setInteriorRedesignTask":
			return {
				...state,
				interiorRedesignTask: action.task,
			};
		case "setInteriorRedesignImg":
			return {
				...state,
				interiorRedesignImg: action.imageData,
			};
		case "setInteriorRedesignResults":
			return {
				...state,
				interiorRedesignResults: [...action.results],
			};
		case "setEditorControls":
			return {
				...state,
				editorControls: {
					...state.editorControls,
					...action.controlsInfo,
				},
			};
		case "setEditorData":
			return {
				...state,
				editorData: action.editorData,
			};
		case "setEraseAndReplaceImg":
			return {
				...state,
				eraseAndReplaceTask: null,
				eraseAndReplaceTasksId: action.ids !== null ? [...action.ids] : [],
				eraseAndReplaceImg: action.img !== null ? [...action.img] : [],
			};
		case "setEraseAndReplaceTask":
			const eraseAndReplaceTask = action.task;

			if (action.task?.status === "COMPLETED") {
				return {
					...state,
					eraseAndReplaceTask: eraseAndReplaceTask,
					eraseAndReplaceTasksId: [...state.eraseAndReplaceTasksId, eraseAndReplaceTask.id],
					eraseAndReplaceImg: [...state.eraseAndReplaceImg, eraseAndReplaceTask.result_url],
					accountData: { ...state.accountData, total_credits: state?.accountData?.total_credits - 1 }
				};
			}

			if (action.task?.status === "FAILED") {
				return {
					...state,
					eraseAndReplaceTask: null,
					eraseAndReplaceTasksId: [...state.eraseAndReplaceTasksId],
					eraseAndReplaceImg: [...state.eraseAndReplaceImg],
				};
			}

			return {
				...state,
				eraseAndReplaceTask: eraseAndReplaceTask,
			};
		case "setExtendImg":
			return {
				...state,
				extendImg: action.img !== null ? [...action.img] : [],
			};
		case "setExtendResults":
			return {
				...state,
				extendResults: [...action.results],
			};
		case "DevMode":
			return {
				...state,
				devMode: action.devMode,
			};
		case "setExtendTask":
			const extendTask = action.task;
			if (action.task?.status === "COMPLETED") {
				return {
					...state,
					extendTask: extendTask,
					extendImg: [...state.extendImg, extendTask.result_url],
				};
			}

			return {
				...state,
				extendTask: extendTask,
			};
		case "setEditorImage":
			return {
				...state,
				editorImage: action.imageData,
			};
		case "setEditorResult":
			return {
				...state,
				editorResult: action.imageData,
			};
		default:
			console.error("Action type not found");
			break;
	}
}

const GlobalContext = createContext({
	state: {},
	dispatch: () => { },
});
export { GlobalContext };

export function useGlobalState() {
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
	return { state, dispatch };
}

export function useStore() {
	const { state, dispatch } = useContext(GlobalContext);
	return { state, dispatch };
}
