import axios from "axios";

const address = process.env.NEXT_PUBLIC_API_URL;

const headersAuthFormData = (token) => {
	return {
		"Content-Type": "multipart/form-data",
		Authorization: `Bearer ${token}`,
	};
};

const applyPromocode = (data) => {
	return axios.post(`${address}/v1/coupons/${data.promocode}/apply/`, data, {
		headers: { ...headersAuthFormData(data.token) },
		validateStatus: function (status) {
			return status;
		},
	});
};

const userSubscription = (data) => {
	return axios.post(`${address}/blog/subscribe/`, data, {
		headers: { "Content-Type": "application/json" },
		validateStatus: function (status) {
			return status;
		},
	});
};

const userRegister = (data) => {
	return axios.post(`${address}/users/`, data, { headers: { "Content-Type": "application/json" }, validateStatus: function (status) { return status; }, }).then(res => res).catch(err => err);
};

const userAuth = (authData) => {
	return axios.post(`${address}/auth/`, authData, { headers: { "Content-Type": "application/json" }, validateStatus: function (status) { return status; }, }).then(res => res).catch(err => err);
};

const userForgotPass = (data) => {
	return axios.post(`${address}/account/reset-password/`, data, {
		headers: { "Content-Type": "application/json" },
		validateStatus: function (status) {
			return status;
		},
	});
};

const userProfileUpdate = (data, token) => {
	let formData = new FormData();
	formData.append("avatar", data.avatar_new);
	formData.append("current_password", data.current_password);
	formData.append("new_password", data.new_password);
	formData.append("confirm_password", data.confirm_password);

	return axios.put(`${address}/v1/profile/`, formData, {
		headers: { ...headersAuthFormData(token) },
		validateStatus: function (status) {
			return status;
		},
	});
};

const addToFavorites = async ({ id, token }) => {
	return await axios.post(
		`${address}/v1/backgrounds/${id}/favorite/`,
		{},
		{
			headers: { Authorization: `Bearer ${token}` },
		}
	);
};

const removeFromFavorites = async ({ id, token }) => {
	return await axios.delete(`${address}/v1/backgrounds/${id}/favorite/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const getFavoritesBg = async ({ group, token }) => {
	let headers = { "Content-Type": "multipart/form-data" };

	if (token) {
		headers = { ...headersAuthFormData(token) };
	}
	return await axios.get(`${address}/v1/backgrounds/?group=${group}&favorite=1`, {
		headers: headers,
	});
};

const getBgCategories = async ({ group, locale }) => {
	let headers = {};
	return await axios.get(`${address}/v1/backgrounds/categories/?group=${group}&locale=${locale}`, {
		headers: headers,
	});
};

// TODO
const getSearchResults = async ({ query, lang }) => {
	let headers = {};
	return await axios.get(`${address}/v1/images/search?q=${query}&lang=${lang}`, {
		headers: headers,
	});
};

const getBg = async ({ token, group, category }) => {
	let headers = {};
	if (token) {
		headers = { Authorization: `Bearer ${token}` };
	}
	if (category) {
		return await axios.get(`${address}/v1/backgrounds/?group=${group}&category_id=${category}`, {
			headers: headers,
		});
	} else {
		return await axios.get(`${address}/v1/backgrounds/?group=${group}`, {
			headers: headers,
		});
	}
};

const getEditorControls = async (control, token) => {
	let headers = { "Content-Type": "multipart/form-data" };
	if (token) {
		headers = { ...headersAuthFormData(token) };
	}
	return await axios.get(`${address}/v1/editor/${control}/`, {
		headers: headers,
	});
};

const runTask = (job_id, taskType, bgId = null, token, plan, editorData, params) => {
	let headers = { "Content-Type": "multipart/form-data" };

	if (token) {
		headers = { ...headersAuthFormData(token) };
	}

	let formData = new FormData();
	formData.append("job", job_id);
	formData.append("task_type", taskType);
	if (token) {
		formData.append("plan", plan);
	}
	if (bgId) {
		formData.append("bg_id", bgId);
	}
	if (editorData) {
		formData.append("mask_scale_factor", editorData.scale);
		formData.append("mask_pos_x", editorData.left);
		formData.append("mask_pos_y", editorData.right);
		let layers = [];
		editorData.chosenEdit.map((item) => {
			layers = [...layers, item];
		});
		layers = [
			...layers,
			{
				type: "b64image",
				data: editorData.frontLayerBlobImg,
			},
		];

		formData.append("layers", JSON.stringify(layers));
	}
	if (params) {
		formData.append("quality", params.quality);
		formData.append("output_format", params.format);
	}

	return axios.post(`${address}/tasks/`, formData, {
		headers: headers,
		validateStatus: function (status) {
			return status < 500;
		},
	});
};

const getTaskStatus = (task_id) => {
	return axios.get(`${address}/tasks/${task_id}/`);
};

const getPlans = (currency) => {
	if (!currency) return axios.get(`${address}/v1/pricing/`);

	const queryParams = { currency: currency ? currency : '' }
	return axios.get(`${address}/v1/pricing/`, { params: { ...queryParams } });


};

const getCurrency = async () => {
	const { data } = await axios.get(`${address}/v1/billing/currency/ `);
	return data?.currency
}

const getSubscribeUrl = (id, credits, token, currency = "USD", callback_url) => {
	let data = {};
	data = credits ? { ...data, credits: credits } : { ...data };
	data = callback_url ? { ...data, callback_url: callback_url } : { ...data };

	return axios.post(`${address}/v1/plans/${id}/subscribe/?currency=${currency}`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getPayAsGoId = (currency, price, token) => {
	let data = {
		"currency": currency,
		"price": price
	}

	return axios.post(`${address}/v1/payments/`, data, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const uploadUserBg = (token, file) => {
	let headers = { "Content-Type": "multipart/form-data" };

	if (token) {
		headers = { ...headersAuthFormData(token) };
	}
	let formData = new FormData();
	formData.append("file", file);
	return axios.post(`${address}/v1/backgrounds/`, formData, {
		headers: headers,
	});
};

const getAccountData = (token) => {
	return axios.get(`${address}/v1/profile/ `, {
		headers: { Authorization: `Bearer ${token}` },
		validateStatus: function (status) {
			return status;
		},
	});
};

const deleteAccount = (token) => {
	return axios.delete(`${address}/v1/profile/ `, {
		headers: { Authorization: `Bearer ${token}` },
		validateStatus: function (status) {
			return status;
		},
	});
};

const unsubscribe = (token) => {
	return axios.post(`${address}/v1/profile/unsubscribe/`, {}, { headers: { Authorization: `Bearer ${token}` } });
};

const getUserTransactions = (token) => {
	return axios.get(`${address}/v1/profile/transactions/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const getUserOperations = (token) => {
	return axios.get(`${address}/v1/billing/operations/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const getTrimmedVideoJob = (job_id) => {
	return axios.get(`${address}/jobs/${job_id}/`);
};

const recoveryJob = (token, job_id) => {
	return axios.get(`${address}/jobs/${job_id}/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const getUserVideo = (token) => {
	return axios.get(`${address}/account/videos/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const deleteBg = (id, token) => {
	return axios.delete(`${address}/v1/backgrounds/${id}/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const deleteVideo = (id, token) => {
	return axios.delete(`${address}/account/videos/${id}/`, {
		headers: { Authorization: `Bearer ${token}` },
	});
};

const checkAvailability = async () => {
	let response = await axios.get(`${address}/config/`);
	return response?.data;
};

const questionnaire = async (data) => {
	return axios.post(`${address}/surveys/`, data, {
		headers: { "Content-Type": "application/json" },
		validateStatus: function (status) {
			return status;
		},
	});
};

const getApiKey = async (data, token) => {
	return axios.post(`${address}/apikeys/`, data, {
		headers: { Authorization: `Bearer ${token}` },
		validateStatus: function (status) {
			return status;
		},
	});
};

const getApiKeysList = async (token) => {
	return axios.get(`${address}/apikeys/`, {
		headers: { Authorization: `Bearer ${token}` },
		validateStatus: function (status) {
			return status;
		},
	});
};

const delApiKey = async (id, token) => {
	return axios.delete(`${address}/apikeys/${id}`, {
		headers: { Authorization: `Bearer ${token}` },
		validateStatus: function (status) {
			return status;
		},
	});
};

const getApiSubscriptionLink = async (token) => {
	return axios.post(
		`${address}/subscribe/`,
		{},
		{
			headers: { Authorization: `Bearer ${token}` },
			validateStatus: function (status) {
				return status;
			},
		}
	);
};

const getApiSubscriptionSettingLink = async (token) => {
	return axios.post(
		`${address}/billing/`,
		{},
		{
			headers: { Authorization: `Bearer ${token}` },
			validateStatus: function (status) {
				return status;
			},
		}
	);
};

const setRating = async (data) => {
	return axios.post(`${address}/v1/ratings/`, data, {
		headers: { "Content-Type": "application/json" },
		validateStatus: function (status) { return status; },
	});
};

export {
	applyPromocode,
	userRegister,
	userAuth,
	userForgotPass,
	userProfileUpdate,
	address,
	getBgCategories,
	getBg,
	runTask,
	getTaskStatus,
	uploadUserBg,
	getAccountData,
	deleteAccount,
	recoveryJob,
	getUserVideo,
	deleteBg,
	deleteVideo,
	checkAvailability,
	questionnaire,
	getApiKey,
	getApiKeysList,
	delApiKey,
	getApiSubscriptionLink,
	getApiSubscriptionSettingLink,
	userSubscription,
	getFavoritesBg,
	addToFavorites,
	removeFromFavorites,
	getTrimmedVideoJob,
	getEditorControls,
	getSearchResults,
	getPlans,
	getSubscribeUrl,
	unsubscribe,
	getUserTransactions,
	getUserOperations,
	setRating,
	getPayAsGoId,
	getCurrency
};
