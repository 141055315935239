import App from "next/app";
import { useEffect, useCallback, useState } from "react";
import Router, { useRouter } from "next/router";
import Head from "next/head";
import withYM from "next-ym";
import { useGlobalState, GlobalContext } from "../stores/use_global_state.js";
import { runTask, getAccountData } from "./api/api";
import * as ga from "../lib/ga";
import { parseJwt } from "../utils/jwt";
import "../styles/style.scss";
import ErrorBoundary from "../components/errorBoundary.jsx";

function handleContextMenu(event) {
	!process.env.NEXT_PUBLIC_API_URL.includes("https://dev.bgrem.ai/api") && event.preventDefault();
}

function MyApp(ctx) {
	const { Component, pageProps, savedLanguage } = ctx;
	const router = useRouter();
	const { locale, query, pathname, asPath } = router;
	const [savedLanguageState, setSavedLanguageState] = useState(savedLanguage);
	const pageName = router.pathname.split("/").pop();
	const { state, dispatch } = useGlobalState();
	const contextValue = { state, dispatch };
	const { job, userInfo } = state;

	// инициализация приложения
	const appInit = useCallback(
		async () => {
			if (savedLanguageState) {
				if (locale == "en" && savedLanguageState !== "en") {
					document.cookie = `NEXT_LOCALE=${savedLanguageState}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
					router.push({ pathname, query }, asPath, { locale: savedLanguage });
					setSavedLanguageState(null);
				}

			}

			try {
				const userData = JSON.parse(localStorage.isLogged);

				if (userData) {
					const { token } = userData;
					const { status } = await getAccountData(token);

					if (status > 399) {
						dispatch({ type: "setLogOut" }); router.push({ pathname: "/sign-in/" }); return;
					}
					dispatch({ type: "setAuth", userInfo: userData });
				}
			} catch (error) {
				console.error;
			}

			dispatch({ type: "setLoadingStatus" });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, router, savedLanguageState]
	);

	useEffect(
		() => {
			appInit();
			window.addEventListener("contextmenu", handleContextMenu);
			// запуск дев режима
			window.enabledDevelopmentMode = () => {
				dispatch({ type: "DevMode", devMode: true });
				window.removeEventListener("contextmenu", handleContextMenu);
				console.log("%cDevelopment Mode is active", "color: green; font-size: large");
			};
			// отключение дев режима
			window.disableDevelopmentMode = () => {
				dispatch({ type: "DevMode", devMode: false });
				window.addEventListener("contextmenu", handleContextMenu);
				console.log("%cDevelopment Mode is deactivated", "color: red; font-size: large");
			};
			return () => {
				window.removeEventListener("contextmenu", handleContextMenu);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// инициализация приложения end

	// авторизация

	useEffect(() => {
		if (state?.load && state?.userInfo !== null) {
			return;
		}

		if (state?.load && state?.userInfo === null) {
			const freeLimit = JSON.parse(localStorage.getItem("freeLimit"));

			if (freeLimit === null) {
				const limit = { backgroundRemoving: 40, image: 20 };
				localStorage.setItem("freeLimit", JSON.stringify(limit));
			}
		}
	}, [state?.load, state?.userInfo]);

	const checkQuery = useCallback(
		() => {
			if (state?.load) {
				let { query } = router;

				if (query?.event) {
					if (query.event === "user_signin") {
						ga.event({ action: `Use third-party service`, params: { event_category: "sign-in" } });
					}
					if (query.event === "user_signup") {
						ga.event({ action: `Use third-party service`, params: { event_category: "sign-up" } });
					}
				}
				if (query?.jwt) {
					const jwtData = parseJwt(query?.jwt)
					if (jwtData) {
						const userData = { isLogged: true, rememberMe: true, token: query?.jwt, userData: jwtData, };
						ga.event({ action: `sign in - through third-party services`, params: { event_category: "Auth" } });
						localStorage.setItem("isLogged", JSON.stringify(userData));
						dispatch({ type: "setAuth", userInfo: userData, load: false });

						if (pageName === "thank") { router.push({ pathname: "/" }, { locale: locale }); }
					} else {
						delete query?.jwt;
						router.push({ pathname, query: query });
					}
				}

				if (query?.purchased && query?.cs_plan) {
					ga.event({ action: "plan_purchased", params: { event_label: `plan: ${query?.cs_plan} min` } });
				}

				try {
					let saveBgData = JSON.parse(localStorage.activeGroup);
					if (pageName === saveBgData.type) {
						if (job === null) {
							dispatch({ type: "setRecoveryJob", job: saveBgData.job, task: null, option: saveBgData.option, previewImg: saveBgData.previewImg, editorData: saveBgData.editorData });
						}
					} else {
						dispatch({ type: "setRecoveryJob", job: null, task: null, option: null, previewImg: null, editorData: null });
					}
				} catch (error) { console.error; }
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[router, state?.load]
	);

	useEffect(() => {
		checkQuery();
	}, [router.query, checkQuery]);

	const logOut = useCallback(() => {
		dispatch({ type: "setLogOut" });
	}, [dispatch]);

	useEffect(() => {
		if (userInfo !== null) {
			const timeoutInit = () => {
				setTimeout(() => {
					console.log("checking token expiration date")
					let timeNow = new Date().valueOf();
					let timeTokenEnd = userInfo.userData.exp * 1000;
					let timeout = timeTokenEnd - timeNow - 60000 * 60;
					timeout < 1800000 ? logOut() : timeoutInit();
				}, 1800000)
			}

			timeoutInit();
		}
	}, [userInfo, logOut]);

	useEffect(
		() => {
			if (userInfo) {
				const getUserData = async () => {
					let { status, data } = await getAccountData(userInfo.token);

					if (status > 399) { logOut(); }

					ga.userPlan(data?.plan);

					window.fpr("referral", { email: data?.email });

					let restrictions = state.restrictions.map((item) => {
						item.active = item.status === data.plan;
						return item;
					});

					dispatch({ type: "setAccountData", accountData: data, restrictions: restrictions });
				};

				getUserData();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userInfo, logOut]
	);

	// авторизация end

	// Google Analytics

	useEffect(
		() => {
			if (router.isReady) {
				const path = router.locale !== "en" ? "/" + router.locale + router.pathname : router.pathname;
				if (userInfo) {
					ga.pageview(path, String(userInfo.userData.user_id));
					return;
				}
				ga.pageview(path);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[router]
	);

	// Google Analytics end

	const clearCheckout = useCallback(() => {
		if (router.pathname !== "/remove-video-bg" && state.checkout) {
			dispatch({ type: "setCheckout", checkout: null });
		}
	}, [state, router, dispatch]);

	useEffect(() => {
		clearCheckout();
	}, [router, clearCheckout]);

	const initTask = useCallback(async () => {
		let { userInfo, accountData, isAuth, job, previewImg, task } = state;
		let token = userInfo?.token;
		let jobExamination = job && job.status !== "previewDone";
		let previewExamination = !previewImg;
		let taskExamination = !task;

		if (jobExamination && previewExamination && taskExamination) {
			const plan = isAuth ? accountData?.plan : "start";
			let { data } = await runTask(job.id, "preview", null, token, plan);
			dispatch({ type: "setTask", action: "init", task: data });
		}
	}, [dispatch, state]);

	useEffect(
		() => { initTask(); },
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[job]
	);

	return (
		<ErrorBoundary>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>

			<GlobalContext.Provider value={contextValue}>
				<Component {...pageProps} />
			</GlobalContext.Provider>
		</ErrorBoundary>
	);
}

MyApp.getInitialProps = async (appContext) => {
	const { ctx } = appContext;

	const compareContext = await App.getInitialProps(appContext);

	const locale = ctx?.req?.cookies.NEXT_LOCALE || ctx?.locale || "en";

	return { ...compareContext, savedLanguage: locale };
};

export default withYM("78207268", Router)(MyApp);
